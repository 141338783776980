.relative-picker-dropdown {
    width: 200px;
    height: 130px;
    border-radius: 5px;
    padding: 14px 16px 14px 16px;
}
.relative-picker-dropdown .caption {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.relative-picker-dropdown .relative-time-input {
    padding: 8px 8px 8px 10px;
    width: 80px;
    height: 32px;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #181818;
    margin-right: 5px;
}
.relative-picker-dropdown .ant-select-selection-item {
    font-weight: 600 !important;
}
